@import url(https://fonts.googleapis.com/css?family=Raleway);
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

html,body{
      background-color: white;
    }

    html{
      position:relative;
      min-height: 100%;
    }

    body{
      margin-bottom: 60px;
      font-family: 'Raleway', serif;
      color:#555555;
      letter-spacing: 0.5px;
    }

.container{

      width:90%;
      margin: auto;

}

.navbar-default .navbar-brand,.navbar-default .navbar-nav > li > a{
  color:#FFFFFF;
}


button,h1,h2,h3,h4,h5,li,label{
  text-transform:uppercase;
  letter-spacing: 1px;
}

.alert{
  position: absolute;
  z-index:10;
  right:20px;
  bottom:20px;
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    background-color: #555555;
    text-align: center;
    color: white;
    font-size: 11px;
    padding-top: 10px;

  }

  #footer a{
    color: white;
  }



table td, table th{
  text-align: center;

}

table>tr>td{
    vertical-align: middle;
}


@media screen {
div.divFooter {
  display: none;
}
}
@media print {
div.divFooter {
  position: fixed;
  bottom: 0;
}
}


#dynamic_checkbox{
  position:absolute;
  top:0px;
  z-index: 100;
}


.navbar {
  background: #003938;
  background: -webkit-linear-gradient(left, #00bcbc, #003938); /* For Safari 5.1 to 6.0 */
   background: -o-linear-gradient(right, #00bcbc, #003938); /* For Opera 11.1 to 12.0 */
   background: -moz-linear-gradient(right, #00bcbc, #003938); /* For Firefox 3.6 to 15 */
   background: linear-gradient(to right, #00bcbc, #003938); /* Standard syntax */

}

a{
  color:#00bcbc;
}

a:hover{
  color: #00ae3f;

}

.btn-default, .btn-default:active, .btn-default:visited,.btn-primary, .btn-primary:active, .btn-primary:visited {
  background-color: #00ae3f;
  color:white;
  border: none;
}


.btn-default:hover,.btn-primary:hover{
  background-color: #ffe64e;
  color:#555555;

}

.left-align{
  width: 80%;
  border:1px solid #CCC;
  margin:auto;
}

.left-align td{
  text-align:left
}
